import axios from 'axios'
import { msalPluginInstance } from '@/utilities/MsalPlugin'
import * as Sentry from '@sentry/vue'

export interface EntityRequest<T> {
  entity: T
}

export default class EntityService<T> {
  base = ''
  type = ''

  constructor(endpoint: string, type: string) {
    this.base = endpoint
    this.type = type
  }

  async FetchAll(): Promise<T[]> {
    let results: T[] = []

    try {
      const token = await msalPluginInstance.acquireApiToken()
      const response = await axios.get<T[]>(`${this.base}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      results = response.data
    } catch (error) {
      Sentry.captureException(error)
    }

    return results
  }

  async Fetch(id: string): Promise<T | Record<string, never>> {
    let results: T | Record<string, never> = {}

    try {
      const token = await msalPluginInstance.acquireApiToken()
      const response = await axios.get<T[]>(`${this.base}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      results = response.data[0]
    } catch (error) {
      Sentry.captureException(error)
    }

    return results
  }

  async Upsert(request: EntityRequest<T>): Promise<void> {
    try {
      const token = await msalPluginInstance.acquireApiToken()
      await axios.post<string>(
        `${this.base}`,
        {
          user: request.entity
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
    } catch (error) {
      Sentry.captureException(error)
    }
  }
}
